import React, {useCallback, useEffect, useState, useRef} from 'react'
import { Capacitor} from "@capacitor/core";
import { requestRefreshToken, getParamOutOfHashList, authenticate, LoginType, AuthCheckContext, getHashTokenType, getSignInWithMagicLink, parseError, resolveCodeFlowToPolicy } from '../../helpers/authHelpers/authService'
import { resolveLogoutUrl, POLICIES } from '../../helpers/authHelpers/authConstants'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import './AuthCheck.css'
import AuthCheckMessageComponent, {AuthCheckMessageComponentProps} from './AuthCheckMessageComponent';
import AutoLogout from './AutoLogout';
import { isError } from 'shared-utils';
import { getLogo } from '../../helpers/Utils';

const AuthCheck: React.FC = props => {
    const [authStatus, setAuthStatus] = useState<{idToken?: string, availableAccounts?:{cacheData: CachedObjectState}, authHeaderSet: boolean}>({authHeaderSet: false})
    const [authCheckMessageState, setAuthCheckMessageState] = useState<AuthCheckMessageComponentProps>();
    const signInPolicy = useRef<Auth.LoginPolicies>()
    
    const setErrorState = () => setAuthCheckMessageState({
        title: 'We are having trouble logging you in.',
        content: 'Please contact our office at (239) 333-4863.',
        buttonOnClickEvent: () => logout(),
        buttonText: 'Try Again' 
    })

    const setFailedPasswordReset = () => setAuthCheckMessageState({
        title: 'Failed to reset password',
        content: 'Please try again or call (239) 333-4863 for assistance.',
        buttonOnClickEvent: () => logout(),
        buttonText: undefined
    })

    const setPasswordResetSuccess = () => setAuthCheckMessageState({
        title: 'Password reset link sent',
        content: 'Please check your email for a link to finish resetting your password.',
    })

    //Interceptors
    /**
     * handle error response
     * If an error occurs we check if it's a 401, if it is we attempt to get a new refresh token
     * any other error is pushed back to the original caller.
     */
     const handleErrorResponse = useCallback((error: AxiosError)=>{
        interface RequestWithRetry extends AxiosRequestConfig{
            _retry: boolean
        }
        const originalRequest = error.config as RequestWithRetry
        
        if (error.response?.status === 403 && error.response?.data === 'missing personAccountId') {
            setErrorState()
        }
        
        if(error.response?.status === 401 && !originalRequest._retry){
            originalRequest._retry = true
            return getAccessTokenFromApiServer().then((response)=>{
                //retry request with new axios instance, to avoid reusing interceptors
                let newAxiosInstance = axios.create()
                originalRequest.headers.Authorization = response?.data?.idToken
                setAuthStatus((prevState)=>{
                    return {...prevState, idToken: response?.data?.idToken}
                })
                //return the call so the original caller can handle the result

                return newAxiosInstance.request(originalRequest)
            },(rejectReason)=>{
                //logout()
            })
        }
        return Promise.reject(error)
    },[])


    
    /**
     * Implicitly set the authorization header to all requests
     * 
     * if you want to make a request without the authorization header make a new instance of axios
     * AuthHeaderSet allows us to know when axios is fully initialized and we are ready to render
     * the rest of the app.
     */
    const addAuthHeader = useCallback((req: AxiosRequestConfig)=>{
        let token = authStatus?.idToken
        req.headers.Authorization = token
        req.withCredentials = true
        return req
    },[authStatus.idToken])

    useEffect(()=>{
        if(!authStatus?.idToken){
            return
        }

        let subscriptionNumber = axios.interceptors.request.use(addAuthHeader)

        setAuthStatus(prevState=>({...prevState,  authHeaderSet: prevState.idToken !== undefined}))

        return ()=>{ axios.interceptors.request.eject(subscriptionNumber) }
    },[addAuthHeader, authStatus.idToken])

    const initSession = useCallback(async (idToken: string, refreshToken: string, signInPolicy: Auth.LoginPolicies) => {
        let result = await axios.post('/init-session', { idToken, refreshToken, signInPolicy })
        if(result?.data){
            setAuthStatus({authHeaderSet: false, idToken: idToken, availableAccounts: result?.data})
        }

        /**
         * This was a band-aid to some issue
         setTimeout(()=>{
             axios.post('/set-refresh-token', { refreshToken })
            }, 100)
        */
    }, [])
    
    const getAccessTokenFromApiServer = ()=>{
        return axios.get<Auth.AuthResponse>('/auth')
    }

    const submitPasswordResetSelfService = (hashParams: string) => {
        //devise a better way of getting this value
        const idTokenParam = getParamOutOfHashList('id_token', hashParams.split('&'))
        return axios.get<Auth.AuthResponse>(`/reset-password/${idTokenParam}`)
    }

    const loginAttemptCount = useRef<number>(0);

    const login = useCallback(async (loginType: LoginType = {flow: 'regular'})=>{
        try {
            const tokenResponse = await authenticate({...loginType})
            //for ios and android
            //may want to add try/catch incase init fails
            
            signInPolicy.current = POLICIES.signIn

            await initSession(tokenResponse.id_token, tokenResponse.refresh_token, signInPolicy.current)
        } catch(err) {
            if(isError(err) && err.message === 'USER_CANCELLED') {
                login()
            } else {
                /*
                    forcing a re-login if an unknown/other error occurs curing auth
                    to prevent possible infinite loops i'm limiting this type of reset to 3 attempts. 
                */

               if (++loginAttemptCount.current > 3) {            
                   setErrorState();
                   return
               }
               login()
            }
        }
    }, [initSession])
   
    const passwordReset = useCallback( async (username: string, firstName: string) => {
        login({flow: 'passwordReset', username, firstName})
    }, [login])
    
    const logout = useCallback( async () => {
        try{
            let newAxiosInstance = axios.create()
            await newAxiosInstance.post('/logout')
        }catch(err){
            //noop
        }
        
        if(Capacitor.isNative){
            window.history.pushState({}, '', '/');
            setAuthStatus({authHeaderSet: false})
        }else{
            window.location.href = resolveLogoutUrl(signInPolicy.current || POLICIES.signIn) 
        }
    }, [])

    useEffect(()=>{
        let resSubNumber = axios.interceptors.response.use((res)=>{
            return res
        },handleErrorResponse)

        return ()=>{
            axios.interceptors.response.eject(resSubNumber)
        }
    },[handleErrorResponse, logout])

    const AuthCheckContextRef = useRef({
        logout,
        resetPassword: passwordReset
    })

    /**
     * Web based login
     */
    const checkHashForWebLogin = useCallback(async (hashParams: string)=>{
        const hashType = getHashTokenType(hashParams)
        if(hashType === 'reset-self-service'){
            try {
                const result = await submitPasswordResetSelfService(hashParams)
                if(result.status === 200){
                    return setPasswordResetSuccess() 
                }
            } catch(err) {
                setFailedPasswordReset()
            }
        }

        if(hashType === 'magic-link-hint'){
            try {
                const magicLink = await getSignInWithMagicLink(hashParams)
                window.location.href = magicLink
            } catch(err){
                setErrorState()
            }
            return
        }

        if(hashType === 'code-flow' || hashType === 'magic-link-code' || hashType === 'password-reset-code'){
            try{
                signInPolicy.current = resolveCodeFlowToPolicy(hashType)

                const refreshResponse = await requestRefreshToken(hashParams, hashType);
                await initSession(refreshResponse.tokenResponse.id_token, refreshResponse.tokenResponse.refresh_token, signInPolicy.current) //check for valid response
                window.history.replaceState(undefined, refreshResponse.pathFromState, refreshResponse.pathFromState) //set pathName to match original
            }catch(err){
                setErrorState()
            }

            return
        }

        if(hashType === 'error') {
            const errorMode = parseError(hashParams)
            if(errorMode === 'REDIRECT_TO_LOGIN') {
                login()
            }
            
            if(errorMode === 'REDIRECT_TO_MY_PROFILE') {
                await checkForRefreshTokenLoginOnFail()
                window.history.replaceState(undefined, '/my-profile', '/my-profile')
                return
            }

            if(errorMode === 'SHOW_TOO_MANY_ATTEMPTS_ERROR'){
                return setFailedPasswordReset()
            }

            setErrorState()
        }

        return

    },[ initSession ])

    const checkForRefreshTokenLoginOnFail = useCallback(async () => {
        try {
            const refreshResponse = await getAccessTokenFromApiServer(); 
            if(refreshResponse?.data?.idToken) {
                signInPolicy.current = refreshResponse.data.signInPolicy
                setAuthStatus((prev)=>({...prev, idToken: refreshResponse?.data?.idToken}))
            }else{
                //if the url response does not contain an id_token
                login()
            }
        } catch(err) {
            /**
             * if the request fails, which can happen for a variety of reasons
             * e.g. Refresh token is not saved, refresh token is stale
             * Redirect the user to login
             */
            login()
        }
    },[login])

    const completeWebBasedFlowOrInitWebBaseLogin = useCallback(()=>{
        let hashParams = window.location.hash
        if(hashParams){
            checkHashForWebLogin(hashParams)
        }

        if(!hashParams && !authStatus.idToken){
            checkForRefreshTokenLoginOnFail()
        }

    }, [checkHashForWebLogin, checkForRefreshTokenLoginOnFail, authStatus.idToken])

    useEffect(() => {
        completeWebBasedFlowOrInitWebBaseLogin()
    },[ completeWebBasedFlowOrInitWebBaseLogin ])

    const renderChildren = () => {
        return React.Children.map(props.children, (child, index)=>{
            if(typeof child === 'object'){
                //lazy checking type. This should generally work
                return React.cloneElement(child as React.ReactElement<any>, {accounts: authStatus?.availableAccounts})
            }else{
                return child
            }
            //return child
        })
    }

    if (authCheckMessageState) {
        return (
            <AuthCheckContext.Provider value={AuthCheckContextRef}>
                <AuthCheckMessageComponent {...authCheckMessageState} />
            </AuthCheckContext.Provider>
        )
    }

    //handle render
    if(!authStatus.authHeaderSet){
        return (<div data-test='loading-animation' className='auth-container'>
                        <img className='auth-center' src={getLogo()} width="100%" alt="Equity Trust logo"/>
                        <div className='auth-animation'/>
                    </div>)
    } else{
        return  <AuthCheckContext.Provider value={AuthCheckContextRef}>
                    <AutoLogout logout={logout}>
                        <div data-test='loading-children'>{ renderChildren() }</div>
                    </AutoLogout>
                </AuthCheckContext.Provider>
    }
}

export default AuthCheck